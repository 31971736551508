.hero-mobile {
  margin: 40px; }

.content {
  margin-left: 24px;
  margin-right: 24px;
  margin-bottom: 24px;
  margin-top: 100px; }
  @media all and (max-width: 644px) {
    .content {
      margin-top: 160px; } }
  @media all and (min-width: 1300px) {
    .content {
      max-width: 1200px;
      margin-left: auto;
      margin-right: auto; } }

.detail-content {
  margin-left: 24px;
  margin-right: 24px;
  margin-bottom: 24px;
  margin-top: 100px; }
  @media all and (max-width: 644px) {
    .detail-content {
      margin-top: 160px; } }
  @media all and (min-width: 1100px) {
    .detail-content {
      max-width: 1000px;
      margin-left: auto;
      margin-right: auto; } }

.card-layout {
  display: flex;
  justify-content: center; }
