body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}


.hero-mobile {
  margin: 40px; }

.content {
  margin-left: 24px;
  margin-right: 24px;
  margin-bottom: 24px;
  margin-top: 100px; }
  @media all and (max-width: 644px) {
    .content {
      margin-top: 160px; } }
  @media all and (min-width: 1300px) {
    .content {
      max-width: 1200px;
      margin-left: auto;
      margin-right: auto; } }

.detail-content {
  margin-left: 24px;
  margin-right: 24px;
  margin-bottom: 24px;
  margin-top: 100px; }
  @media all and (max-width: 644px) {
    .detail-content {
      margin-top: 160px; } }
  @media all and (min-width: 1100px) {
    .detail-content {
      max-width: 1000px;
      margin-left: auto;
      margin-right: auto; } }

.card-layout {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center; }

.nav-toolbar {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-flow: wrap;
          flex-flow: wrap;
  -webkit-align-content: flex-end;
          align-content: flex-end;
  -webkit-justify-content: space-between;
          justify-content: space-between; }
  @media all and (max-width: 644px) {
    .nav-toolbar {
      -webkit-justify-content: space-around;
              justify-content: space-around;
      padding-top: 16px; } }
  @media (min-width: 960px) {
    .nav-toolbar .MuiTab-root {
      font-size: 0.875rem; } }

.nav-title {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  margin-bottom: 4px; }
  @media all and (max-width: 644px) {
    .nav-title {
      -webkit-align-items: center;
              align-items: center; } }

.nav-tabs {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row; }
  @media all and (max-width: 644px) {
    .nav-tabs {
      margin-bottom: 0; } }

.card-project {
  height: 100%;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column; }
  @media screen and (max-width: 440px) {
    .card-project span.MuiButton-label {
      width: 100%; } }

.card-project-details {
  -webkit-flex-grow: 1;
          flex-grow: 1; }

.card-project-title {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-align-items: baseline;
          align-items: baseline; }

.card-project-media {
  height: auto;
  overflow: hidden; }

.stepper-container {
  display: -webkit-flex;
  display: flex; }
  @media all and (min-width: 800px) {
    .stepper-container {
      -webkit-justify-content: space-around;
              justify-content: space-around; } }
  @media screen and (max-width: 440px) {
    .stepper-container span.MuiButton-label {
      width: -webkit-min-content;
      width: -moz-min-content;
      width: min-content; } }

.project-section-content {
  margin-right: 16px;
  margin-left: 16px;
  margin-top: 16px;
  margin-bottom: 32px; }

.project-section-row {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-justify-content: flex-start;
          justify-content: flex-start; }

.project-section-left {
  margin-right: 16px; }

.project-section-right {
  margin-left: 16px; }

.project-section-col {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-content: space-between;
          align-content: space-between; }

.project-section-top {
  margin-bottom: 16px; }

.project-section-bottom {
  margin-bottom: 16px; }

.project-section-img {
  width: 100%;
  height: auto;
  object-fit: contain;
  margin-top: 24px; }

.project-section-row-img {
  width: 100%;
  height: auto;
  object-fit: contain;
  margin-top: 4px; }

.project-extra-img {
  width: 100%;
  height: auto;
  object-fit: contain;
  margin-top: 0; }

.card-feature {
  display: -webkit-flex;
  display: flex;
  height: 100%; }

.card-details {
  -webkit-flex: 1 1;
          flex: 1 1; }

.card-media {
  width: 100%; }

.hero-about {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
  margin-top: 24px;
  margin-bottom: 16px; }

.login-field {
  margin-top: 16px; }

.login-error .MuiTypography-body2-73 {
  color: #f44336;
  margin-top: 0;
  margin-bottom: 0; }

.login-actions {
  margin-bottom: 16px;
  margin-right: 16px; }

