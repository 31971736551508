.nav-toolbar {
  display: flex;
  flex-flow: wrap;
  align-content: flex-end;
  justify-content: space-between; }
  @media all and (max-width: 644px) {
    .nav-toolbar {
      justify-content: space-around;
      padding-top: 16px; } }
  @media (min-width: 960px) {
    .nav-toolbar .MuiTab-root {
      font-size: 0.875rem; } }

.nav-title {
  display: flex;
  flex-direction: column;
  margin-bottom: 4px; }
  @media all and (max-width: 644px) {
    .nav-title {
      align-items: center; } }

.nav-tabs {
  display: flex;
  flex-direction: row; }
  @media all and (max-width: 644px) {
    .nav-tabs {
      margin-bottom: 0; } }
