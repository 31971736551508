.project-section-content {
  margin-right: 16px;
  margin-left: 16px;
  margin-top: 16px;
  margin-bottom: 32px; }

.project-section-row {
  display: flex;
  flex-direction: row;
  justify-content: flex-start; }

.project-section-left {
  margin-right: 16px; }

.project-section-right {
  margin-left: 16px; }

.project-section-col {
  display: flex;
  flex-direction: column;
  align-content: space-between; }

.project-section-top {
  margin-bottom: 16px; }

.project-section-bottom {
  margin-bottom: 16px; }

.project-section-img {
  width: 100%;
  height: auto;
  object-fit: contain;
  margin-top: 24px; }

.project-section-row-img {
  width: 100%;
  height: auto;
  object-fit: contain;
  margin-top: 4px; }

.project-extra-img {
  width: 100%;
  height: auto;
  object-fit: contain;
  margin-top: 0; }
