.card-project {
  height: 100%;
  display: flex;
  flex-direction: column; }
  @media screen and (max-width: 440px) {
    .card-project span.MuiButton-label {
      width: 100%; } }

.card-project-details {
  flex-grow: 1; }

.card-project-title {
  display: flex;
  flex-direction: row;
  align-items: baseline; }

.card-project-media {
  height: auto;
  overflow: hidden; }
